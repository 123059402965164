@import './styles';

// CONFIGURATIONS
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
   //  text-decoration: none;
    border: 0;
    outline: none;
}

ul {
    list-style: none;

}


// COMMON STYLING 
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
   display: flex;
   flex-direction: column;
}

.email-name {
   color: $secondary-color;
}
.card {
    justify-content: left;
    align-items: flex-start;
    width: 100%;
  
}

.container-card {
    box-shadow: $box-shadow-1;
    position: relative;
    width: 30%;
    padding: 10px;

    border-radius: $border-rd-6;
    -webkit-border-radius: $border-rd-6;
    -moz-border-radius: $border-rd-6;
    -ms-border-radius: $border-rd-6;
    -o-border-radius: $border-rd-6;
 }

 .left-aligned-gap-1 {
    gap: 1rem;
 }

 .just-content-space-btwn {
   justify-content: space-between;
 }

 .pop-over-wrapper {
    width: 60%;
    padding: 16px 36px;
    position: relative;

    overflow: hidden;
    background-color: $white-color;
 }

 .po-container {
   width: 80%;
   box-shadow: $box-shadow-2;

   border-radius: $border-rd-6;
   -webkit-border-radius: $border-rd-6;
   -moz-border-radius: $border-rd-6;
   -ms-border-radius: $border-rd-6;
   -o-border-radius: $border-rd-6;
}

// ADDED NEW CLASS TO INCREASE THE HEIGHT OF EACH SECTION AND PADDING
.section-min-h {
   min-height: 80vh;
   
}

// padding y-axis
.section-p-y {
  padding: 2rem 1rem;
}