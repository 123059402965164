/* VARIABLES */
$bg-1-color: #fff;
$main-color: #381B5D;
$bg-2-color:#FBF9F9;
$green-color: #1ED709;
$border-color: #0000;
$orange-color: #D75B09;
$red-color: #D70909;
$secondary-color: #5E0ACC;
$black-color: #333;

$white-color: rgba(255, 255, 255, 0.8);
$gray-color: rgba(204, 203, 203, 0.909);

$sm-opacity: 0.3;
$md-opacity: 0.5;
$lg-opacity: 1;

$sm-font-size: 1rem;
$regular-font-sz:1.125rem;
$md-font-size: 1.25rem;
$lg-font-size: 1.863rem;

$light-fw: light;
$regular-fw: 100;
$bold-fw: 700;
$semi-bold-fw: 600;

$border-rd-3: 3px;
$border-rd-6: 6px;
$border-rd-10: 10px;
$border-rd-20: 20px;


$box-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.199);
$box-shadow-2: 0 2px 6px rgba(0, 0, 0, 0.201);
$box-shadow-3: 0 3px 6px rgba(0, 0, 0, 0.199);


h1 {
    font-size: $lg-font-size;
    font-weight: $semi-bold-fw;
}

h2 {
    font-size: $md-font-size;
    font-weight: 600;
}

h4 {
   font-size: $md-font-size;
   font-weight: $regular-fw; 
}

p{
    font-size: $sm-font-size;
    font-weight: $light-fw;
}