html,
body {
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 2.2%;
}
/* Nav div style */
nav {
  background-color: #f6f4f3;
  /* background-color: #f03a47; */
  padding: 0% !important;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05), 0 1px 4px hsla(0, 0%, 0%, 0.05),
    0 2px 8px hsla(0, 0%, 0%, 0.05);
}
.c {
  width: 80% !important;
}

#logo {
  width: 100px;
  height: auto;
}

.nav-link {
  color: #183059 !important;
}
.nav-link.active {
  color: #276fbf !important;
}

.contact-btn {
  display: inline-block;
  background-color: #183059;
  text-decoration: none;
  color: #fff;
  width: 100px;
  height: 26px;
  border-radius: 3px;
  text-align: center;
}

/* HOME TOP DIV STYLE */
.hm-wlcm-row {
  color: #183059;
  padding: 4% 12%;
  height: auto;
}

.hm-wlcm-col.left h1 {
  font-weight: bolder;
}
.hm-wlcm-col.left button {
  margin: 2%;
  padding: 1% 5%;
  border-radius: 3px;
  width: 35%;
}
.get-start-btn {
  background-color: #183059;
  color: #fff;
  margin-left: 0 !important;
}

.watch-shwrl-btn {
  border: 2px solid #c2c2c2;
  background-color: transparent;
}

.behind-dv {
  bottom: 50%;
  left: 20%;
  width: 80%;
  height: 70%;
  margin-bottom: 6%;
}
.behind-dv,
.abt-behind-dv {
  position: relative;
  background-color: #183059;
  color: #fff;
}
.behind-dv p {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  padding-right: 3%;
}
#phone-24 {
  display: inline-block;
  position: relative;
  left: 30%;
  width: 64px;
  height: auto;
}

/* Portifolio div style */
.portfolio-dv {
  height: 200px;
  text-align: center;
}
.portfolio-dv img {
  width: 15%;
  height: 140px;
}

/* featurers div style */
.features-dv {
  height: auto;
  background-color: #f6f4f3;
  text-align: center;
  padding: 4% 0;
}

.features-dv h1 {
  color: #183059;
  width: 70%;
  font-size: 350%;
  margin-bottom: 3%;
}
.ftr-p {
  color: #183059;
  margin-bottom: 2%;
  font-size: 150%;
  width: 40%;
}
.ftr-card {
  width: 75%;
  margin: 0 10%;
}
.ftr-img {
  min-width: 50%;
  max-width: 60%;
  height: auto;
  margin: auto;
}
.exp-year {
  margin-top: 3%;
  color: #183059;
  font-size: xx-large;
}
.exp-year span {
  color: #f03a47;
  font-weight: bolder;
}

/* Malorum div style */
.malorum,
.about-4-dv {
  height: auto;
  background-color: #276fbf;
  padding: 3% 9%;
  color: #fff;
}
.malorum h1,
h2 {
  margin-bottom: 2rem;
}

.malorum p {
  width: 50%;
}

.malorum img {
  width: 90%;
  height: 300px;
  border-radius: 20px;
}
.services-btn {
  margin: 0 3% 3% 0;
  padding: 1% 6%;
  background-color: #183059;
  border-radius: 6px;
  color: #fff;
}
.services-btn:hover {
  background-color: #f6f4f3;
  border: 1px solid #183059;
  color: #000;
}
.services-btn.proj {
  background-color: #f6f4f3;
  border: 1px solid #183059;
  color: #000;
}
.services-btn.proj:hover {
  background-color: #183059;
  color: #fff;
}

/* Specialities div style */
.specialities {
  height: auto;
  background-color: #f6f4f3;
  color: #183059;
}

.spec-outer-h1,
.spec-outer-p {
  width: 40%;
  padding-left: 3%;
  margin-bottom: 2%;
}

.ftr-card.left {
  background-color: #f03a47;
  color: #fff;
}
.ftr-card.middle,
.ftr-card.right {
  background-color: #e0dbdb;
}

/* Footer div style */
footer {
  height: auto;
  background-color: #183059;
  padding: 3% 12% 0;
  color: #fff;
}
.footer-row {
  padding: 0 auto;
}
.footer-contact-icon {
  width: 20px;
  height: 20px;
  margin-left: 2%;
}
footer img {
  width: 150px;
  height: 50px;
  margin: 1% 0;
}

footer a {
  display: block;
  text-decoration: none;
  color: #e0dbdb;
}

footer p {
  color: #e0dbdb;
}
#footer-logo-h {
  margin: 0;
  color: #f03a47;
}
.footer-logo-p {
  color: #fff;
  margin-bottom: 1%;
}
.footer-social-a {
  display: inline;
}
.footer-socio-icons {
  width: 20px;
  height: 20px;
  margin: 0 1%;
}
#copyright {
  display: block;
  width: 100%;
  text-align: center;
  color: #e0dbdb;
}

/* ABOUT PAGE STYLE */
.about-1-dv h1,
.trusted-h {
  color: #183059;
  font-weight: bolder;
}
.about-1-dv span,
.trusted-h span {
  color: #f03a47;
}
#wallLogo {
  width: 90%;
  height: 70%;
  position: relative;
  z-index: 1;
}
.abt-behind-dv {
  bottom: 50%;
  opacity: 0.3;
  height: 70%;
}
.about-1-dv button {
  padding: 1% 6%;
  background-color: #183059;
  color: #fff;
  border-radius: 6px;
  margin: 6%;
}
.about-2-dv {
  padding-top: 3%;
}
.circle-dv {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6px solid #183059;
  background-color: #fff;
}
.connector {
  position: relative;
  bottom: 84px;
  left: 230px;
  width: 70%;
  height: 6px;
  border: none;
  background: #183059;
  opacity: unset;
  z-index: -1;
}
.about-3-dv {
  background-color: #f6f4f3;
  color: #183059;
  text-align: center;
  padding: 3% 30%;
}
.about-3-dv p {
  font-size: 200%;
}
.about-4-dv {
  background: #0a1833;
  color: #fff;
}
.about-4-dv h1 {
  margin: 1% 0 4%;
  text-align: center;
}
.about-4-dv p {
  font-size: 120%;
}
#mission-img {
  width: 80%;
  height: 350px;
}
.about-4-dv small {
  color: #dcdee1;
}
.about-5-dv {
  background-color: #f6f4f3;
  color: #183059;
}
.about-5-dv p,
.about-6-dv p {
  color: #183059;
}
.about-6-dv {
  background-color: #fff;
  color: #183059;
}

/* Our Team
-------------------------------------------------------*/

.our-team .team-row {
  margin-left: -40px;
  margin-right: -40px;
}

.our-team .team-wrap {
  padding: 0 40px;
}

.our-team .container-fluid {
  padding: 0 50px;
}

.team-img img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  width: 200px;
  height: 200px;
}

.team-member,
.team-img {
  position: relative;
  overflow: hidden;
}

.team-img {
  width: 200px;
  height: 200px;
  border: 6px solid #f03a47;
  border-radius: 50%;
  margin: auto;
}

.team-title {
  margin: 30px 0 7px;
}

.overlay {
  background-color: rgba(20, 20, 20, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.team-details {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.team-details p {
  color: #fff;
}

.team-img:hover .team-details {
  opacity: 1;
  margin-top: -80px;
}

.team-img:hover .overlay {
  opacity: 1;
}

.socials a {
  display: inline-block;
  width: 37px;
  height: 37px;
  background-color: transparent;
}

.socials i {
  line-height: 37px;
  color: #616161;
  font-size: 14px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.team-details .socials i {
  color: #fff;
}

.socials a:hover i {
  color: #fff;
  background-color: #355c7d;
}

/* SERVICES PAGE STYLE */
.service-0-dv span {
  color: #f03a47;
}

.serve-img {
  width: 60%;
  position: relative;
  float: right;
}
.services-1-dv {
  padding: 5% 0 5% 12%;
  color: #fff;
  background-image: linear-gradient(
    to left,
	    /* #183059, */
    rgb(39, 111, 191, 0.3),
    rgb(39, 111, 191, 1)
  );
}
.product-device {
  background-image: url("../images/malorum.PNG");
}
.services-1-dv .right {
  padding-top: 2%;
  text-align: center;
}
.services-2-dv {
  padding: 6% 15%;
  text-align: center;
  color: #183059;
  background-color: #f6f4f3;
}
.services-2-dv h1 {
  margin-bottom: 3%;
}
.services2-ext-p {
  padding: 0 20%;
  margin-bottom: 6%;
}

.services-3-dv {
  padding-top: 2%;
}
.get-span {
  color: #f03a47;
}
.services3-row {
  margin: 0 0;
}
.services-3-dv .card {
  background-color: transparent;
  border: none;
}
.services3-img {
  width: 100px;
  height: 100px;
  margin-bottom: 5%;
}
/* PORTIFOLIO PAGE STYLE */
.partiner-0-dv {
  padding: 3% 12%;
  color: #183059;
}
.partiner-0-dv h1 {
  font-weight: bolder;
}
.partiner-0-dv p {
  font-size: larger;
}
.partiner-0-dv button {
  border: 1px solid #183059;
  padding: 1% 6%;
  background-color: #fff;
}
.partiner-0-dv button:hover {
  color: #fff;
  background-color: #183059;
}
.non-matrix-wrapper {
  width: 30%;
}
.matrix-wrapper {
  position: relative;
  width: 70%;
}
.matrix-dv {
  position: absolute;
  width: 300px;
  height: 300px;
  border: 6px solid #f03a47;
}
.matrix-top {
  right: 0;
}
.matrix-center {
  top: 19%;
  right: 17%;
  background-color: #fff;
  border: 9px solid #183059;
  z-index: 1;
  padding-top: 9%;
}
.matrix-center img {
  width: 80%;
  height: 50%;
}
.matrix-btm {
  bottom: 0%;
  right: 35.5%;
}
.partiners-logo {
  display: block;
  width: 50%;
  height: 90px;
}
.phoenix,
.britam,
.alliance,
.strategies,
.mgen {
  position: relative;
}
.phoenix,
.strategies {
  left: 20%;
}
.britam {
  left: 45%;
}
.alliance {
  left: 50%;
}
.mgen {
  left: 25%;
}
.portifolio-1-dv {
  padding: 4% 12%;
  color: #183059;
  background-color: #f6f4f3;
}
.portifolio-1-dv h1 {
  /* width: 50%; */
  font-weight: bolder;
}
.portifolio-1-dv p {
  margin-bottom: 6%;
  font-weight: bolder;
  width: 90%;
}
.portifolio-1-dv img {
  width: 100%;
  height: 350px;
}
.portifolio-2-dv {
  padding: 6% 12%;
    /* background-color: #183059; */
  background-color: #276fbf;
  color: #fff;
}
.portifolio-2-dv p {
  width: 60%;
}
.portifolio-3-dv {
  padding: 0 7%;
}
.portifolio-3-dv img {
  width: 192px;
  height: 100px;
  margin: 1% 5%;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2183059%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23183059%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

/* SWIPER JS SLIDER STYLING STARTS HERE */
  .swiper {
   height: 33vh;
  }
/* SWIPER JS SLIDER STYLING ENDS HERE */

/* GALLERY PAGE STYLES */
.gallery-0-dv {
  padding: 0 6%;
  height: 500px;
}
.gallery-0-dv .col {
  position: relative;
}
.gallery-0-dv h1 {
  width: 70%;
  color: #183059;
}
.gallery-0-dv h1 span {
  color: #f03a47;
}
.left-cut-dv,
.right-cut-dv {
  position: absolute;
  background-color: #f03a47;
  width: 60%;
  height: 40%;
}
.left-cut-dv {
  bottom: 0;
  left: 20%;
  border-radius: 50px 50px 0 0;
}
.right-cut-dv {
  left: 20%;
  background-color: #183059;
  border-radius: 0 0 50px 50px;
}
.gallery-0-dv img {
  position: absolute;
  left: 25%;
  bottom: 10%;
  height: 30%;
}
.gallery-1-dv {
  height: 500px;
  background-image: url("../images/engineer.PNG");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
}
.gallery-1-dv div {
  height: 500px;
  background-color: rgb(24, 48, 89, 0.2);
  padding: 4% 12%;
}
.gallery-1-h,
.gallery-1-p {
  width: 40%;
  font-weight: bolder;
}
.gallery-1-h,
.gallery-1-p,
.gallery-1-btn {
  margin: 2% 0;
}
.gallery-1-dv button {
  padding: 0.5% 5%;
  background-color: #fff;
  border-radius: 9px;
  color: #183059;
  font-weight: bolder;
}
.gallery-2-dv {
  padding: 2% 2%;
}
.gallery-2-dv h1 {
  margin-bottom: 2%;
  padding-left: 3%;
}
.gallery-card img {
  width: 300px;
  height: 200px;
}
.gallery-card h3 {
  margin: 4% 0;
}
.gallery-cols {
  width: 25%;
  margin: 3% 3%;
}

/* CONTACT PAGE STYLE */
.contact-2-dv {
  padding: 4% 30%;
  background-color: #276fbf;
}
.contact-2-dv h1 {
  color: #fff;
}
.contact-3-dv {
  padding: 6%;
  color: #183059;
}
/* Set the size of the div element that contains the map */
#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

@media only screen and (min-width: 760px) {
  .hm-wlcm-row {
    height: auto;
  }
  .hm-wlcm-col.left p,
  .hm-wlcm-col.left h1 {
    width: 50%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    padding-top: 15%;
  }
  .hm-wlcm-col.left {
    text-align: center;
  }
  .hm-wlcm-col.right {
    margin-top: 5%;
    height: 300px;
  }
  .hm-wlcm-col.left button {
    width: 46%;
  }
  .behind-dv {
    bottom: 25%;
    height: 50%;
    margin-bottom: 0%;
  }
  .features-dv h1 {
    margin-top: 10%;
    width: 100%;
    font-size: xx-large;
  }
  .ftr-p {
    width: 85%;
    font-size: larger;
    margin-bottom: 10%;
  }
  .malorum {
    text-align: center;
  }
  .malorum p {
    width: 100%;
  }

  .portifolio-3-dv img {
    width: 83px;
    height: 70px;
    margin: 3% 2%;
  }
  .spec-outer-h1,
  .spec-outer-p {
    width: 100%;
    margin-bottom: 6%;
  }
  .about-1-dv {
    padding: 6% 10%;
    height: auto;
  }
  .about-1-dv .col {
    margin-top: 6%;
  }
  .about-1-dv h1 {
    width: 100%;
  }
  #wallLogo {
    width: 70%;
    height: 60%;
  }
  .abt-behind-dv {
    height: 60%;
    opacity: 0.3;
  }
  .about-2-dv {
    padding-top: 11%;
  }
  .connector {
    bottom: 85px;
    left: 70px;
  }
  .about-3-dv {
    padding: 3% 10%;
  }
  .about-4-dv small {
    display: block;
    margin-bottom: 10%;
  }
  .serve-img {
    float: none;
    display: inline-block;
    margin-left: 18%;
    width: 200px;
    height: 200px;
    bottom: -100px;
  }
  .services-1-dv {
    text-align: center;
    padding: 0;
  }
  .services-2-dv {
    padding: 6% 10%;
  }
  .services2-ext-p {
    padding: 0 10%;
  }
  .partiner-0-dv {
    padding: 2% 6%;
  }
  .non-matrix-wrapper {
    width: 100%;
  }
  .matrix-wrapper {
    width: 100%;
    height: 300px;
  }
  .matrix-dv {
    width: 200px;
    height: 200px;
  }
  .partiners-logo {
    width: 40%;
    height: 63px;
  }
  .portifolio-1-dv {
    text-align: center;
  }
  .portifolio-1-dv h1 {
    width: 100%;
  }
  .portifolio-2-dv p {
    width: 80%;
  }
  .left-cut-dv {
    height: 20%;
  }
  .gallery-0-dv img {
    left: 38%;
  }
  .gallery-1-dv {
    height: 350px;
  }
  .gallery-1-dv div {
    height: 350px;
    text-align: center;
  }
  .gallery-1-h,
  .gallery-1-p {
    width: 100%;
    font-weight: bolder;
  }
  .gallery-cols {
    width: 100%;
    padding: 6% 9%;
    margin: 0;
  }
  .contact-1-dv h1 {
    width: 100% !important;
  }
  .contact-2-dv {
    padding: 3% 15%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
